import { useProductTypeById } from "../hooks/SettingsHooks";
import { useSiteFeatures } from "../hooks/useSiteFeatures";
import { Trans } from "react-i18next";
import apiService from "../services/apiService";
import { multiply } from "./NumberUtils";
import { TDictionary, useProductTranslations } from "../hooks/TranslationHooks";
import uniq from "lodash/uniq";
import { translateComingDate } from "./DateUtils";
import { productTypeBaseUrl } from "../config/urls";
import {
  categoriesByProduct,
  hasConceptCategory,
  hasOutletCategory,
  productCategoryHasParent,
  productCategoryUrl,
  useCategoriesByProduct,
  useCategoryByProduct
} from "./ProductCategoryUtils";
import { useMemo } from "react";
import { Language, languages } from "../config/languages";
import { Product, ProductPriceUnit } from "../types/Product";
import { ProductCategory } from "../types/ProductCategory";
import find from "lodash/find";
import { CartItem } from "../types/CartItem";
import { StockStatus } from "../types/ProductStock";
import { ProductType } from "../types/ProductType";
import { DateTime } from "luxon";
import { TFunction } from "i18next";

export function _productFlags(
  product: Product,
  shopFeature: boolean,
  maxFlags = 4
) {
  const flags: string[] = [];

  const unavailable =
    (product.unavailable && !product.upcoming) ||
    (shopFeature &&
      !product.upcoming &&
      product?.stock?.status === StatusCodes.soldOut &&
      product.discontinued);
  if (unavailable) {
    return ["unavailable"];
  }

  let outlet = false;
  if (shopFeature && hasOutletCategory(product)) {
    flags.push("outlet");
    outlet = true;
  } else if (hasConceptCategory(product)) {
    flags.push("concept");
  }
  const flagAttrs = ["upcoming"];

  /** Can't be both discontinued and outlet */
  if (!outlet) {
    flagAttrs.push("discontinued");
  }

  /** Can't be both upcoming and new */
  if (!product.upcoming) {
    flagAttrs.push("new_product");
  }
  if (shopFeature) flagAttrs.push("campaign_product", "exclusive");

  flags.push(...flagAttrs.filter(flag => product[flag]));
  return uniq(flags).slice(0, maxFlags);
}

export function useProductFlags(product: Product) {
  const features = useSiteFeatures();
  const tp = useProductTranslations();
  const flags = _productFlags(product, features.shop(), 4);
  return flags.map(flag => (
    <span
      key={flag}
      className={flag}
      title={
        tp("flag." + flag + ".descr") !== "flag." + flag + ".descr"
          ? tp("flag." + flag + ".descr")
          : ""
      }
    >
      <Trans ns={"products"} i18nKey={"flag." + flag} />
    </span>
  ));
}

export function productUrl(
  product?: Product,
  category?: ProductCategory,
  lang?: Language
): string | undefined {
  if (!product) {
    return undefined;
  }
  const { name, sku, art_id } = product;
  if (category) {
    const categoryUrl = productCategoryUrl(category, lang);
    const slug = (name || "")
      .trim()
      .toLowerCase()
      .replace(new RegExp("\\W+", "g"), "-");
    return `${categoryUrl}/${sku || art_id}-${slug}`;
  } else {
    return `/article/${sku || art_id}`;
  }
}

export function productUrls(product?: Product, categories?: ProductCategory[]) {
  return categoriesByProduct(product, categories)
    .map(category => productUrl(product, category))
    .filter((url): url is string => !!url);
}

export function useProductUrl(
  product?: Product,
  preferredCategory?: ProductCategory
) {
  const category = useCategoryByProduct(product);
  const categories = useCategoriesByProduct(product);
  return useMemo(() => {
    if (preferredCategory && categories.length > 1) {
      const preferred = categories.filter(cat =>
        productCategoryHasParent(cat, preferredCategory, true)
      );
      if (preferred.length > 0) {
        return productUrl(product, preferred[0]);
      }
    }
    return productUrl(product, category);
  }, [product, preferredCategory, category, categories]);
}

export function useProductSupportUrl({
  name,
  sku,
  art_id,
  productType: productTypeId
}: Partial<Product>): string {
  const productType = useProductTypeById(productTypeId);
  const path = productType?.path || "uncategorized";
  const slug = (name || "")
    .trim()
    .toLowerCase()
    .replace(new RegExp("\\W+", "g"), "-");
  return `${productTypeBaseUrl}/${path}/${sku || art_id}-${slug}`;
}

export function productPaths(product?: Product, category?: ProductCategory) {
  return Object.fromEntries(
    languages.map(lang => [lang, productUrl(product, category, lang)])
  );
}

export function getProductByCockpitId(
  products: Product[],
  _id?: string
): Product | undefined {
  if (!_id) {
    return undefined;
  }
  return find<Product>(products, { _id } as object);
}

export function getProductBySku(
  products: Product[],
  sku?: string
): Product | undefined {
  if (!sku) {
    return undefined;
  }
  const f = products.filter(
    product => product.sku === sku || product.sampleSku === sku
  );
  return f.length ? f.pop() : undefined;
}

export function getProductsBySkus(
  products?: Product[],
  skus?: string[]
): Product[] {
  return products && Array.isArray(skus)
    ? skus
        .map(sku => getProductBySku(products, sku))
        .filter((product): product is Product => !!product)
    : [];
}

export async function getProductAsync(
  products: Product[],
  artId: string,
  lang = "",
  withAuth: boolean
): Promise<Product | undefined | { error: boolean; statusCode: number }> {
  if (!artId) {
    return undefined;
  }
  const product = getProductBySku(products, artId);
  if (product) {
    return product;
  } else {
    try {
      return await apiService.loadProduct(artId, lang, withAuth);
    } catch (error) {
      console.error("forced getProduct failed: " + artId, error);
      return { error: true, statusCode: 404 };
    }
  }
}

export function productIsUnavailable(product: Product): boolean {
  return (
    product.unavailable === true ||
    (product.discontinued === true &&
      product?.stock?.status === StatusCodes.outOfStock)
  );
}

export function productIsPurchasable(product: Product, _b2b: boolean): boolean {
  const { unavailable, upcoming } = product;
  const status = product?.stock?.status;
  return !(
    unavailable ||
    upcoming ||
    status === StatusCodes.soldOut ||
    status === StatusCodes.outOfStock
  );
}

export function productHasStock(
  product: Product,
  amount: number,
  b2b?: boolean
): boolean {
  const stock =
    b2b === true && product.partnerStock ? product.partnerStock : product.stock;
  const availableStock = stock?.availableStock || "0";
  const availableUnitStock = stock?.availableUnitStock || "0";
  const { unitQuantityFactor } = product;
  const isPerSquareMeter = productIsPerSquareMeter(unitQuantityFactor);
  const itemAmount = isPerSquareMeter
    ? multiply(unitQuantityFactor, amount)
    : amount;
  const inStock = parseFloat(availableUnitStock) || parseInt(availableStock);
  return inStock >= itemAmount;
}

export function productStockStatus(
  product: Product,
  cartItem: CartItem,
  _tp: TFunction,
  b2b?: boolean
): {
  icon: string;
  cartIcon: string;
  message: string;
  amount: string;
} {
  const tp = (key: string, options?: TDictionary<string>): string => {
    const defaultValue = _tp(key, options);
    return b2b === true
      ? _tp(`${key}.b2b`, { ...options, defaultValue })
      : defaultValue;
  };
  if (!product?.stock) {
    //console.warn("productStockStatus empty data", product);
    return {
      icon: "empty",
      cartIcon: "empty",
      message: tp("shipping_status.unavailable"),
      amount: ""
    };
  }
  const {
    status,
    shippingDate,
    nextRestockDate,
    availableStock,
    availableUnitStock,
    incomingStock: _incomingStock,
    incomingUnitStock
  } = product.stock;
  const incomingStock = _incomingStock || "0";
  const { unavailable, unitQuantityFactor, discontinued, upcoming } = product;
  const isPerSquareMeter = productIsPerSquareMeter(unitQuantityFactor);
  const priceUnit = productPriceUnit(unitQuantityFactor);
  const itemAmount: number = cartItem?.amount
    ? isPerSquareMeter
      ? multiply(unitQuantityFactor, cartItem.amount)
      : cartItem.amount
    : 0;
  const inStock =
    parseFloat(availableUnitStock || "") || parseInt(availableStock || "");
  const stockWarnLimit = availableUnitStock ? 100 : 2;

  let icon = "";
  let cartIcon = "";
  const messages: string[] = [];
  let amount = "";
  if (cartItem?.isSample) {
    return { icon: "", cartIcon: "", message: "", amount: "" };
  }
  if (upcoming) {
    icon = "time";
    messages.push(tp("upcoming_stock"));
  } else {
    if (status === StatusCodes.readyToShip && inStock > 0) {
      // I lager
      amount = tp("products.in.stock", {
        stock: availableUnitStock || availableStock || "",
        unit: tp(
          (availableUnitStock ? "price_unit." : "sales_unit.") + priceUnit
        )
      });
      if (itemAmount === 0 || inStock >= itemAmount) {
        messages.push(
          tp("shipping_status." + status, {
            day: translateComingDate(shippingDate, _tp).translation
          })
        );
      }
      if (inStock < itemAmount) {
        cartIcon = "empty";
      }
      if (inStock >= stockWarnLimit) {
        icon = "ok";
      } else {
        icon = "warn";
        cartIcon = "ok";
        if (itemAmount > 0 && inStock < itemAmount) {
          messages.push(tp("stock.cart.warning"));
          cartIcon = "empty";
        }
      }
      if (inStock < 1000) messages.push(getRestockMsg(true));
    }
    if (status === StatusCodes.backOrder) {
      // Beställd till lagret
      icon = "warn";
      messages.push(tp("shipping_status." + status));
      messages.push(getRestockMsg());
    }
    if (status === StatusCodes.soldOut || status === StatusCodes.outOfStock) {
      if (unavailable || discontinued) {
        // Utgången
        icon = "empty";
        messages.push(tp("shipping_status.unavailable"));
      } else {
        // (Tillfälligt) slut i lager
        icon = "empty";
        messages.push(tp("shipping_status." + status));
        messages.push(getRestockMsg());
      }
    }
  }
  cartIcon = cartIcon || icon;
  return {
    icon,
    cartIcon,
    message: messages
      .filter(m => !!m)
      .join(". ")
      .replace(".. ", ". "),
    amount
  };

  function getRestockMsg(hideUnknown = false) {
    const messages: string[] = [];
    const nextRestockDateObj = DateTime.fromISO(nextRestockDate || "");
    const nextRestockDay = translateComingDate(nextRestockDate, _tp);
    if (
      incomingStock !== "0" &&
      nextRestockDateObj.isValid &&
      nextRestockDateObj.diffNow("years").years < 1 &&
      nextRestockDay.translation
    ) {
      messages.push(
        tp("incoming_stock", {
          incomingStock: incomingUnitStock || incomingStock,
          unit: tp(
            (incomingUnitStock ? "price_unit." : "sales_unit.") + priceUnit
          )
        })
      );
      messages.push(
        tp("incoming_stock_date", {
          nextRestockDate: nextRestockDay.translation
        })
      );
    } else if (!hideUnknown) {
      messages.push(tp("unknown.delivery"));
    }
    return messages.filter(m => !!m).join(" ");
  }
}

export function productIsPerSquareMeter(
  unitQuantityFactor?: number
): unitQuantityFactor is number {
  return typeof unitQuantityFactor === "number" && unitQuantityFactor > 0;
}

export function productPriceUnit(
  unitQuantityFactor?: number
): ProductPriceUnit {
  return productIsPerSquareMeter(unitQuantityFactor)
    ? "per_square_meter"
    : "per_package";
}

export function productSortPrice(product: Product) {
  if (!product.price) {
    return 0;
  }
  return productIsPerSquareMeter(product.unitQuantityFactor)
    ? parseFloat(product.price.unitPrice || "") ||
        parseFloat(product.price.basePrice || "") ||
        0
    : parseFloat(product.price.basePrice || "") || 0;
}

export function productHasSamples({ hasSample, sampleSku }: Partial<Product>) {
  return hasSample && sampleSku != null && sampleSku !== "";
}

export const StatusCodes: Record<string, StockStatus> = {
  readyToShip: "ready_to_ship",
  backOrder: "back_order",
  outOfStock: "out_of_stock",
  soldOut: "sold_out"
};

export const FLOOR_FORMATS = ["S", "M", "L", "XL", "XXL"];

export function splitProductName(
  name: string,
  productType?: ProductType,
  productCategory?: ProductCategory
) {
  const lastPart = name.split(" ").pop();
  const nameSize = FLOOR_FORMATS.includes(lastPart || "")
    ? lastPart
    : undefined;
  //Until we have a product.short_name, create it this way:
  const nameProduct = name
    .replace(productType?.short_name || productCategory?.title || "[SKIP]", "")
    .replace(" " + (productType?.version || "[SKIP]"), "")
    .replace(new RegExp(" " + (nameSize || "\\[SKIP\\]") + "$"), "")
    .trim();
  const nameType = productType?.short_name || productCategory?.title;
  const nameVersion = productType?.version;
  return { nameProduct, nameType, nameVersion, nameSize };
}
