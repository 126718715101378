import axios from "axios";
import { getAuth } from "firebase/auth";
import firebaseConfig from "../config/firebaseConfig";
import {
  enhanceAccessories,
  enhanceClaimsData,
  enhanceGuide,
  enhanceNews,
  enhancePeople,
  enhanceProduct,
  enhanceStory,
  unwrapGuideCategories,
  unwrapImageStyles,
  unwrapRepeater,
  validateShopProduct
} from "../utils/CockpitUtils";
import { getCurrentSite, siteType } from "../utils/SiteUtils";
import { enhanceProductSpecifications } from "../utils/SpecsUtils";
import userService from "./userService";
import partition from "lodash/partition";

function getApiBaseUrl() {
  const baseUrl = import.meta.env.VITE_API_SERVICE_BASE_URL;
  if (baseUrl) {
    return baseUrl;
  } else {
    const envSiteType = import.meta.env.VITE_SITE_TYPE;
    switch (envSiteType || siteType(window.location.hostname)) {
      default:
      case "dev":
      case "local":
        return "https://bjelin-service-dev.herokuapp.com/api";
      case "staging":
        return "https://bjelin-service-staging.herokuapp.com/api";
      case "prod":
        return "https://bjelin-service.bjelin.com/api";
    }
  }
}

export const baseURL = getApiBaseUrl();
console.log("apiService.baseUrl", baseURL);

const anonymousApi = axios.create({
  baseURL
});

const authenticatedApi = axios.create({
  baseURL
});

const firebaseTokenInterceptor = async config => {
  const auth = getAuth(firebaseConfig.getApp());
  const user = auth.currentUser;
  if (user) {
    config.headers.Authorization = "Token " + (await user.getIdToken());
    const secrets = await userService.getDeferredSecrets(
      config?.noSecrets ? 0 : 30000
    );
    if (secrets) {
      config.headers.Secrets = secrets;
    }
  }
  return config;
};

authenticatedApi.interceptors.request.use(firebaseTokenInterceptor);

const fittingApi = () => {
  const auth = getAuth(firebaseConfig.getApp());
  return !auth.currentUser ? anonymousApi : authenticatedApi;
};

const isCancelled = error => axios.isCancel(error);

export const retrowNotCancelledError = error => {
  if (!isCancelled(error)) throw error;
};

export const cancelTokenSource = () => axios.CancelToken.source();

const enhanceOrder = order => {
  order.lines = order.lines.map(line => {
    line.images = line.images.map(unwrapImageStyles);
    return line;
  });
  return order;
};

const apiService = {
  anonymousApi,
  isCancelled,
  loadPage(path, lang, source = cancelTokenSource()) {
    const params = {
      path,
      lang
    };
    console.debug("loadPage", params);
    return new Promise((resolve, reject) => {
      anonymousApi
        .get("/" + getCurrentSite() + "/pages", {
          params,
          cancelToken: source.token
        })
        .then(result => resolve(result.data))
        .catch(error => {
          if (!isCancelled(error)) {
            console.warn("show 404 page", error);
            anonymousApi
              .get("/" + getCurrentSite() + "/pages", {
                params: { path: "/404", lang }
              })
              .then(result => resolve({ ...result.data, statusCode: 404 }));
          } else {
            reject(error);
          }
        });
    });
  },
  loadSettings(lang) {
    return this.loadSettingsForSite(getCurrentSite(), lang);
  },
  loadSettingsForSite(site, lang) {
    const params = {
      lang
    };
    return new Promise((resolve, reject) => {
      const url = "/" + site + "/settings";
      anonymousApi
        .get(url, { params })
        .then(result => {
          const settings = result.data || {};

          settings.specifications = Object.fromEntries(
            Object.entries(settings.specifications).map(([_, sp]) => [
              sp.key,
              {
                ...sp,
                icons: Object.fromEntries(
                  Object.entries(sp.icons).map(([_, icon]) => [
                    icon.key,
                    { ...icon }
                  ])
                )
              }
            ])
          );

          settings.specification_value_order = {};
          Object.entries(settings.specifications).forEach(
            ([specification, values]) => {
              if (typeof values.icons === "object") {
                settings.specification_value_order[specification] = Object.keys(
                  values.icons
                );
              }
            }
          );

          settings.top_navigation.menu_items = unwrapRepeater(
            settings.top_navigation.menu_items
          );

          console.debug("settings", settings);
          resolve(settings);
        })
        .catch(reject);
    });
  },
  loadProduct(sku, lang, withAuth) {
    const api = withAuth ? authenticatedApi : anonymousApi;
    const params = {
      lang
    };
    return new Promise((resolve, reject) => {
      api
        .get("/" + getCurrentSite() + "/product/" + sku, { params })
        .then(({ data: product }) => resolve(enhanceProduct(product)))
        .catch(reject);
    });
  },
  loadLabelProduct(sku, site, lang, key) {
    const params = {
      lang,
      key
    };
    return new Promise((resolve, reject) => {
      fittingApi()
        .get(`${site}/label_product/${sku}`, { params })
        .then(({ data: labelProduct }) => {
          enhanceProduct(labelProduct.product);
          resolve(labelProduct);
        })
        .catch(reject);
    });
  },
  mergeProduct(sku, entry, lang) {
    //Requires admin role!
    const params = {
      lang
    };
    return authenticatedApi.post(
      "/" + getCurrentSite() + "/product/" + sku,
      entry,
      {
        params
      }
    );
  },
  loadPageFragments(lang) {
    const params = {
      lang
    };
    return new Promise((resolve, reject) => {
      anonymousApi
        .get("/" + getCurrentSite() + "/page_fragments", { params })
        .then(result => {
          resolve(result.data.page_fragments || []);
        })
        .catch(reject);
    });
  },
  loadStories(lang, limit) {
    const params = {
      lang,
      limit
    };
    return new Promise((resolve, reject) => {
      anonymousApi
        .get("/" + getCurrentSite() + "/stories", { params })
        .then(result => {
          resolve((result.data.stories || []).map(enhanceStory));
        })
        .catch(reject);
    });
  },
  loadStoryByPath(slug, lang) {
    const params = {
      lang
    };
    return new Promise((resolve, reject) => {
      anonymousApi
        .get("/" + getCurrentSite() + "/stories/" + slug, { params })
        .then(({ data: story }) => resolve(enhanceStory(story)))
        .catch(error => {
          if (!isCancelled(error)) {
            resolve({ statusCode: 404 });
          } else {
            reject(error);
          }
        });
    });
  },
  loadStoryById(id, lang) {
    const params = {
      lang
    };
    return new Promise((resolve, reject) => {
      anonymousApi
        .get("/" + getCurrentSite() + "/stories/id/" + id, { params })
        .then(({ data: story }) => resolve(enhanceStory(story)))
        .catch(error => {
          if (!isCancelled(error)) {
            resolve({ statusCode: 404 });
          } else {
            reject(error);
          }
        });
    });
  },
  loadNews(lang) {
    const params = {
      lang
    };
    return new Promise((resolve, _reject) => {
      anonymousApi
        .get("/" + getCurrentSite() + "/news", { params })
        .then(result => {
          const news = result?.data?.news;
          if (!Array.isArray(news)) resolve([]);
          resolve(
            news
              .filter(item => {
                if (!item.image) {
                  console.warn("removed news article, image is missing", item);
                  return false;
                }
                if (!item.publishedAt) {
                  console.warn(
                    "removed news article, publishedAt is missing",
                    item
                  );
                  return false;
                }
                return true;
              })
              .map(enhanceNews)
          );
        })
        .catch(e => {
          console.error(e);
          resolve([]);
        });
    });
  },
  loadPeople(_lang) {
    return new Promise((resolve, _reject) => {
      anonymousApi
        .get("/" + getCurrentSite() + "/people")
        .then(result => {
          resolve((result.data.people || []).map(enhancePeople));
        })
        .catch(e => {
          console.error(e);
          resolve([]);
        });
    });
  },
  loadGuides(lang) {
    const params = {
      lang
    };
    return new Promise((resolve, reject) => {
      anonymousApi
        .get("/" + getCurrentSite() + "/guides", { params })
        .then(result => {
          resolve({
            guides: (result.data.guides || []).map(enhanceGuide),
            categories: unwrapGuideCategories(result.data.categories || [])
          });
        })
        .catch(reject);
    });
  },
  loadAnimation(id) {
    return new Promise((resolve, reject) => {
      anonymousApi
        .get("/" + getCurrentSite() + "/animation/" + id, {})
        .then(({ data: animation }) => resolve(animation))
        .catch(error => {
          if (!isCancelled(error)) {
            resolve({ statusCode: 404 });
          } else {
            reject(error);
          }
        });
    });
  },
  loadClaims(lang) {
    const params = {
      lang
    };
    return new Promise((resolve, reject) => {
      anonymousApi
        .get("/" + getCurrentSite() + "/claims", { params })
        .then(result => {
          const data = enhanceClaimsData(result.data || {});
          resolve(data);
        })
        .catch(reject);
    });
  },
  createUser(
    accountType,
    password,
    email,
    firstName,
    lastName,
    companyName,
    vatNumber,
    newsletter
  ) {
    const data = {
      accountType,
      password,
      email,
      firstName,
      lastName,
      companyName,
      vatNumber,
      newsletter
    };
    return authenticatedApi.put("/" + getCurrentSite() + "/user", data);
  },
  getAddress(personnummer) {
    const params = { personnummer };
    return anonymousApi.get("/" + getCurrentSite() + "/address", { params });
  },
  getUser() {
    return authenticatedApi.get("/" + getCurrentSite() + "/user", {
      noSecrets: true
    });
  },
  updateCustomer(data) {
    return authenticatedApi.post(
      "/" + getCurrentSite() + "/user/customer",
      data
    );
  },
  updateUser(data) {
    return authenticatedApi.post("/" + getCurrentSite() + "/user", data);
  },
  sendForgotPasswordEmail(email) {
    return authenticatedApi.post(
      "/" + getCurrentSite() + "/user/forgot-password",
      {
        email
      }
    );
  },
  createCheckoutSession() {
    return authenticatedApi.post("/" + getCurrentSite() + "/checkout/session");
  },
  destroyCheckoutSession(id) {
    return authenticatedApi.delete(
      "/" + getCurrentSite() + "/checkout/session/" + id
    );
  },
  getShippingMethods(orderData, lang) {
    return new Promise((resolve, reject) => {
      if (!orderData?.shippingAddress?.zipcode) {
        reject("No shipping zipcode");
      } else {
        fittingApi()
          .post("/" + getCurrentSite() + "/shipping", orderData, {
            params: { lang }
          })
          .then(({ data }) => {
            const parts = partition(data.shippingMethods, s =>
              s.method.startsWith("pickup_")
            );
            data.shippingMethods = [...parts[1], ...parts[0]];
            resolve(data);
          })
          .catch(reject);
      }
    });
  },
  getPaymentMethods(orderData, cartId) {
    return new Promise((resolve, reject) => {
      if (!orderData.totals) {
        reject("No totals supplied!");
      } else {
        fittingApi()
          .post(
            "/" + getCurrentSite() + "/payment_methods?cartId=" + cartId,
            orderData
          )
          .then(({ data: { paymentMethods } }) => resolve(paymentMethods))
          .catch(reject);
      }
    });
  },
  calculateCheckoutTotal(orderData, source = cancelTokenSource()) {
    if (this.calculateCheckoutTotal.source) {
      this.calculateCheckoutTotal.source.cancel();
    }
    this.calculateCheckoutTotal.source = source;
    return authenticatedApi.post(
      "/" + getCurrentSite() + "/checkout/calculate",
      orderData,
      { cancelToken: source.token }
    );
  },
  getStripePaymentIntent(orderData) {
    return authenticatedApi.post(
      "/" + getCurrentSite() + "/checkout/stripe/cc/getPaymentIntent",
      orderData
    );
  },
  stripeCardCheckout(orderData, paymentIntent) {
    return authenticatedApi.post(
      "/" + getCurrentSite() + "/checkout/stripe/cc/putOrder",
      {
        ...orderData,
        paymentIntent
      }
    );
  },
  invoiceCheckout(orderData) {
    return authenticatedApi.post(
      "/" + getCurrentSite() + "/checkout/invoice",
      orderData
    );
  },
  partnerCheckout(orderData, lang) {
    return authenticatedApi.post(
      "/" + getCurrentSite() + "/checkout/partner",
      orderData,
      {
        params: { lang }
      }
    );
  },
  resursCheckout(orderData) {
    return authenticatedApi.post(
      "/" + getCurrentSite() + "/checkout/resurs",
      orderData
    );
  },
  resursCheckoutSuccess(orderUuid) {
    return authenticatedApi.post(
      "/" + getCurrentSite() + "/checkout/resurs/success/" + orderUuid
    );
  },
  getOrders() {
    return authenticatedApi.get("/" + getCurrentSite() + "/orders");
  },
  getPartnerOrders() {
    return authenticatedApi.get("/" + getCurrentSite() + "/partner/orders");
  },
  getPartnerOrder(orderNumber, lang) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .get("/" + getCurrentSite() + "/partner/order/" + orderNumber, {
          params: { lang }
        })
        .then(({ data: order }) => resolve(enhanceOrder(order)))
        .catch(reject);
    });
  },
  getOrder(uuid, lang) {
    const params = { lang };
    return new Promise((resolve, reject) => {
      anonymousApi
        .get("/" + getCurrentSite() + "/order/" + uuid, { params })
        .then(({ data: order }) => resolve(enhanceOrder(order)))
        .catch(reject);
    });
  },
  async loadProducts(lang, features, settings, source = cancelTokenSource()) {
    const params = {
      lang
    };
    return new Promise((resolve, reject) => {
      authenticatedApi
        .get("/" + getCurrentSite() + "/products", {
          params,
          cancelToken: source.token
        })
        .then(result => {
          resolve(
            enhanceAccessories(
              (result.data.products || [])
                .map(enhanceProduct)
                .filter(features?.shop() ? validateShopProduct : i => i)
                .map(product => enhanceProductSpecifications(product, settings))
            )
          );
        })
        .catch(reject);
    });
  },
  getPublicProductsByProductType(productTypeId, lang) {
    const params = {
      productTypeId,
      lang
    };
    return new Promise((resolve, reject) => {
      anonymousApi
        .get(`/${getCurrentSite()}/products`, { params })
        .then(result =>
          resolve((result.data.products || []).map(enhanceProduct))
        )
        .catch(reject);
    });
  },
  getAdmins() {
    //Requires admin role!
    return authenticatedApi.get("/admins");
  },
  addAdmin(email) {
    //Requires admin role!
    return authenticatedApi.post("/admins", { email });
  },
  removeAdmin(uid) {
    //Requires admin role!
    return authenticatedApi.delete("/admins/" + uid);
  },
  getPartners() {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .get("/" + getCurrentSite() + "/partners")
        .then(({ data }) => resolve(data.partners))
        .catch(reject);
    });
  },
  getConnectedPartners() {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .get("/" + getCurrentSite() + "/partners/connected")
        .then(({ data }) => resolve(data.partners))
        .catch(reject);
    });
  },
  getPartnerProducts(pricelistId) {
    return new Promise((resolve, reject) => {
      if (!pricelistId) {
        resolve({ products: [] });
      }
      authenticatedApi
        .get("/" + getCurrentSite() + "/partners/" + pricelistId + "/products")
        .then(result => {
          (result.data.products || []).map(enhanceProduct);
          resolve(result.data);
        })
        .catch(reject);
    });
  },
  getPartnerUsers(customerId) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .get("/" + getCurrentSite() + "/partners/" + customerId + "/users")
        .then(({ data }) => resolve(data.users))
        .catch(reject);
    });
  },
  activatePartnerUser(customerId, payload) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .post(
          "/" + getCurrentSite() + "/partners/" + customerId + "/activateUser",
          payload
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  checkNewPartnerUser(customerId, email) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .get(
          "/" +
            getCurrentSite() +
            "/partners/" +
            customerId +
            "/checkNewUser/" +
            email
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  createContactPerson(customerNumber, payload) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .post(
          "/" +
            getCurrentSite() +
            "/partners/" +
            customerNumber +
            "/contactPerson",
          payload
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  connectPartnerUser(customerNumber, payload) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .post(
          "/" + getCurrentSite() + "/partners/" + customerNumber + "/user",
          payload
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  setPartnerUserStatus(customerNumber, userEmail, active) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .post(
          `/${getCurrentSite()}/partners/${customerNumber}/user/${userEmail}/status`,
          {},
          { params: { active } }
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  connectPartner(customerNumber, connect) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .post(
          "/" +
            getCurrentSite() +
            "/partners/" +
            customerNumber +
            "/connect/" +
            !!connect
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  allowShoppingPartner(customerNumber, allowShopping) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .post(
          "/" +
            getCurrentSite() +
            "/partners/" +
            customerNumber +
            "/allowShopping/" +
            !!allowShopping
        )
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  getPartnerPriceLists() {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .get("/" + getCurrentSite() + "/partners/pricelists")
        .then(({ data }) => resolve(data.priceLists))
        .catch(reject);
    });
  },
  getPriceLists() {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .get("/" + getCurrentSite() + "/pricelists")
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  getProductWarnings(site) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .get("/" + site + "/admin/products/warnings")
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  lookupUser(email) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .get("/" + getCurrentSite() + "/admin/user/lookup/" + email)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  reindexAllProducts() {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .post("/" + getCurrentSite() + "/pricelists/reindex")
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  getLoginTokenByEmail(email) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .post("/" + getCurrentSite() + "/admin/user/token/" + email.trim())
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  postClaims(formData, onUploadProgress = _progress => {}) {
    for (let entry of formData.entries()) {
      console.log("formData:", entry[0], entry[1]);
    }
    return anonymousApi.post("/" + getCurrentSite() + "/claims", formData, {
      onUploadProgress: progressEvent =>
        onUploadProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        ),
      headers: {
        "Content-type": "multipart/form-data"
      }
    });
  },
  loadFaqData(lang) {
    const params = {
      lang
    };
    return new Promise((resolve, reject) => {
      anonymousApi
        .get("/" + getCurrentSite() + "/faq/data", { params })
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  getAdminOrder(docNum, lang) {
    const params = { lang };
    return new Promise((resolve, reject) => {
      authenticatedApi
        .get(`/${getCurrentSite()}/admin/orders/${docNum}`, params)
        .then(({ data: order }) => resolve(enhanceOrder(order)))
        .catch(reject);
    });
  },
  getOrderByOrderNumber(orderNumber) {
    return new Promise((resolve, reject) => {
      authenticatedApi
        .get(`/${getCurrentSite()}/orders?orderNumber=${orderNumber}`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  downloadSpecifications() {
    authenticatedApi
      .get(`/${getCurrentSite()}/admin/products/specifications.csv`, {
        responseType: "blob"
      })
      .then(response => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "specifications.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  },
  getStoreLocations(lang) {
    return new Promise((resolve, reject) => {
      const params = {
        lang
      };
      fittingApi()
        .get(`/${getCurrentSite()}/store_locations`, { params })
        .then(({ data }) => {
          data.locations = data.locations.map(location => {
            location.id = location.gid + "-" + location.row;
            location.type = location.type && location.type.toLowerCase();
            location.chain = location.chain && location.chain.toLowerCase();
            return location;
          });
          resolve(data);
        })
        .catch(reject);
    });
  },
  getStoreLocation(gid, row) {
    return new Promise((resolve, reject) => {
      fittingApi()
        .get(`/${getCurrentSite()}/store_locations/${gid}/${row}`)
        .then(({ data }) => {
          data.id = data.gid + "-" + location.row;
          data.type = data.type && data.type.toLowerCase();
          data.chain = data.chain && data.chain.toLowerCase();
          resolve(data);
        })
        .catch(reject);
    });
  }
};

export default apiService;
